import { Box, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import React from 'react';

function Declaration3({checked, handleChange}) {
  return (
    <Box>
        <>
        <strong>
            Declaration of No Other Income <br />
            Please tick the box below to confirm that you have no
            other income to report for the Assessment Year
            2024-25.
        </strong>
        <FormGroup sx={{ margin: "0px !important" }}>
            <FormControlLabel
            control={
                <Checkbox
                checked={checked}
                onChange={handleChange}
                />
            }
            label="I hereby declare that apart from the income mentioned above, I have no other income to report for the Assessment Year 2024-25."
            />
        </FormGroup>
        <strong>
            If you tick this box, there is no need to proceed to
            fill out the rest of the form.
        </strong>
        <br />
        <br />
        <strong>
            कोई अन्य आय नहीं होने की घोषणा <br />
            कृपया नीचे दिए गए बॉक्स पर टिक करें ताकि यह पुष्टि हो
            सके कि आपके पास वित्तीय वर्ष 2024-25 के लिए रिपोर्ट
            करने के लिए कोई अन्य आय नहीं है।
        </strong>
        <FormGroup sx={{ margin: "0px !important" }}>
            <FormControlLabel
            control={
                <Checkbox
                checked={checked}
                onChange={handleChange}
                />
            }
            label="मैं यहां घोषणा करता/करती हूं कि ऊपर बताई गई आय के अलावा, मेरे पास वित्तीय वर्ष 2024-25 के लिए रिपोर्ट करने के लिए कोई अन्य आय नहीं है।"
            />
        </FormGroup>
        <strong>
            यदि आप इस बॉक्स को टिक करते हैं, तो बाकी फॉर्म भरने की
            आवश्यकता नहीं है।
        </strong>
        </>
  </Box>
  );
}

export default Declaration3;