import React, { useState } from 'react';
import { DatabaseService } from '../../Services/DatabaseService';

function LoginComponent({ handleToken, }) {

    const [phoneNumber, setPhoneNumber] = useState();
    const [numberVerifying, setNumberVerifying] = useState(false);
    const [phoneNumberError, setPhoneNumberError] = useState("");


    const handleSubmit = async () => {
      console.log("submitted_phoneNumber=-------------->",phoneNumber);
      setNumberVerifying(true);
      const regex = /^[6-9]\d{9}$/;
      setPhoneNumberError("");
      if(regex.test(phoneNumber)){
        let checkPhoneNo = await DatabaseService("GET", "driver/"+phoneNumber);
        console.log("checkPhoneNo----------------->",checkPhoneNo);
        if(checkPhoneNo.code === 200){
          let storeToken = localStorage.setItem("userToken",checkPhoneNo?.token);
          setNumberVerifying(false);
          handleToken(checkPhoneNo?.token);
        }
        if(checkPhoneNo.code === 404){
          setPhoneNumberError("Phone Number not found.");
          setNumberVerifying(false);
        }
        if(checkPhoneNo.code === 410){
          setPhoneNumberError(checkPhoneNo.message);
          setNumberVerifying(false);
        }
      }else{
        setPhoneNumberError("Invalid Phone Number.")
        setNumberVerifying(false);
      }
    }

    const handlePhoneNoChange = async (e) => {
      setPhoneNumber(e.target.value);
    }

    const handleKeyDown = (e) => {
      if (e.key === 'Enter') {
        handleSubmit();
      }
    };

  return (
    <>
      <div className=" py-6 flex flex-col justify-center sm:py-12">
        <div className="relative py-3 sm:max-w-xl sm:mx-auto">
            <div
            className="absolute inset-0 bg-gradient-to-b from-green-500 to-green-400 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl">
            </div>
            <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">

            <div className="max-w-md mx-auto">
                <div>
                <h1 className="text-2xl font-semibold">Mobile Number</h1>
                </div>
                <div className="divide-y divide-gray-200">
                <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                <div className="relative">
                    <div className="flex items-center">
                        <span className="absolute left-[2%] bottom-[18%] text-gray-500">+91</span>
                        <input autoComplete="off" id="phone" name="phone" type="number" max="11" 
                              onInput={(e)=>{handlePhoneNoChange(e)}}
                              onKeyDown={handleKeyDown}
                              className="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:border-gray-600 pl-10" 
                              placeholder="Password" />
                        {/* <label for="phone" class="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">
                            Please enter mobile no.
                        </label> */}
                    </div>
                </div>
                    {
                      phoneNumberError ?
                        <p className="text-red-500 text-xs text-center w-full">
                          {phoneNumberError}
                        </p>
                      : null
                    }
                    <div className="relative">
                    <button disabled={numberVerifying} className="bg-green-500 text-white rounded-md px-5 py-1" onClick={()=>{handleSubmit()}} >Submit</button>
                    </div>
                </div>
                </div>
            </div>


            </div>
        </div>
      </div>
    </>
  );
}

export default LoginComponent;