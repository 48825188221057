import './App.css';
import { ThemeProvider, createTheme } from '@mui/material';
import Main from './Components/Main';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Main />
    </ThemeProvider>
  );
}

export default App;

const theme = createTheme({
  typography: {
    fontFamily: '"Roboto", sans-serif',
    allVariants: {
      textTransform: "none",
    },
  },
});