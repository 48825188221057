import React from 'react';

function Thankyou({message}) {
  return (
    <>
      <div className="divide-y divide-gray-300/50">
        <div className="space-y-6 py-8 text-base leading-7 text-gray-600">
            <p className="font-extrabold text-center">
            { message ||  "Thank You for the Submission."}
            </p>
        </div>
      </div>
    </>
  );
}

export default Thankyou;