import { Backdrop, CircularProgress, Typography } from '@mui/material';
import React from 'react';

function BGLoading({FullScreenLoading}) {
  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, flex: 1, flexDirection: 'column' }}
        open={FullScreenLoading}
        >
        <svg width={0} height={0}>
            <defs>
            <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" stopColor="#22c000" />
                <stop offset="100%" stopColor="#4adeFF" />
            </linearGradient>
            </defs>
        </svg>
        <CircularProgress size={50} sx={{ 'svg circle': { stroke: 'url(#my_gradient)' } }} />
        <Typography>Loading...</Typography>
        </Backdrop>
    </>
  );
}

export default BGLoading;