import { Box, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import React from 'react';
import driverPDF from "../../Assets/Note_for_drivers.pdf";

const Declaration1 = ({userData, isTDSAmountCorrect, handleTDSAmount}) => {
  return (
    <Box>
        <p>
        You have completed rides for an aggregate amount of Rs. <span className="">
            &nbsp;{userData.tdsAmount}&nbsp;&nbsp;
        </span>{" "} from BluSmart for the financial year 2023-24. As confirmed by you, we will report such receipts for tax purposes under section 44AD of the Income Tax Act.
        <br />
        Such business income along with any other income, such as gains/loss from selling mutual funds or shares, dividends, interest, etc., will be reported under the new tax regime based on the information contained in the Annual Information Statement (AIS) and Taxpayer Information Summary (TIS).
        <br />
        Please give us your confirmation to file the income tax return for the financial year 2023-24 on the above basis and take the necessary steps to file the tax return.
        </p>
        <br />
        <br />
        <p>
        
        आपने वित्तीय वर्ष 2023-24 के लिए BluSmart से <span className="">
            &nbsp;{userData.tdsAmount}&nbsp;&nbsp;
        </span>{" "} रुपये की कुल सवारी पूरी की है। जैसा कि आपने पुष्टि की है, हम ऐसे प्राप्तियों को आयकर अधिनियम की धारा 44AD के तहत कर उद्देश्यों के लिए रिपोर्ट करेंगे।
        <br />
        ऐसी व्यावसायिक आय के साथ-साथ अन्य किसी भी आय, जैसे म्यूचुअल फंड्स या शेयरों की बिक्री से लाभ/हानि, लाभांश, ब्याज आदि, को वार्षिक सूचना विवरण (AIS) और करदाता सूचना सारांश (TIS) में निहित जानकारी के आधार पर नए कर प्रणाली के तहत रिपोर्ट किया जाएगा।
        <br />
        कृपया हमें वित्तीय वर्ष 2023-24 के लिए आयकर रिटर्न दाखिल करने की पुष्टि दें और कर रिटर्न दाखिल करने के लिए आवश्यक कदम उठाएं।
        </p>
        <br />
        {/* {isTDSAmountCorrect === false ? (
        <>
            <br />
            <div className="w-full flex gap-5">
            <FormGroup sx={{ margin: "0px !important" }}>
                <FormControlLabel
                control={
                    <Checkbox
                    checked={isTDSAmountCorrect}
                    onChange={() => {
                        handleTDSAmount(1);
                    }}
                    />
                }
                label="Do you agree with TDS Amount / क्या आप टीडीएस राशि से सहमत हैं?"
                />
            </FormGroup>
            </div>
        </>
        ) : null} */}
    </Box>
  );
};

export default Declaration1;