import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import TDSForm from './TDSForm';

function Main() {
    
    const router = createBrowserRouter([
      {
        path: '/',
        element : <TDSForm />
      },
    ])

    return (
        <>
          <div className="relative flex min-h-screen flex-col justify-center overflow-hidden bg-gradient-to-t from-green-500 via-green-400 to-green-50 py-6 sm:py-12">
            <RouterProvider router={router} />
          </div>
        </>
     );
}

export default Main;